import axios, { Axios } from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Config from "../config/config";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import AxiosInstance from "../utils/axios-instance";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("myerrand/create-errand/select-type");
    }
  }, []);
  const login = async () => {
    // navigate("/hold");
    try {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/auth/clientLogin`,
        {
          email: email,
          password: password,
        }
      );
      localStorage.setItem("token", data.token);
      // fetch user details
      let response = await AxiosInstance.get(
        `${Config.baseUrl}/users/currentUserDetails`
      );
      if(response.data.isVerified){
        navigate("/myerrand/create-errand/select-type");
      } else{
        navigate("/hold");
      }
    } catch (err: any) {
      
      if (err.response.status === 405) {
        // redirect to email otp page
        let emailstr = err.response.data.message;
        let emailfromerror= emailstr.substr(19,emailstr.length)
        localStorage.setItem("email", emailfromerror);
        navigate("/phone-verified");

      }else if(err.response.status === 406){

        let phonestr = err.response.data.message;
        let phonefromerror= phonestr.substr(19,phonestr.length)
        localStorage.setItem("contactNumber", phonefromerror);
        navigate("/phoneotp-sent");

      } else {
        showNotification({
          id: "Invalid-user",
          disallowClose: true,
          autoClose: 1500,
          title: "User",
          message: "Invalid User",
          color: "red",
          loading: false,
        });
      }
    }
  };
  return (
    <>
      <div className="offset-7 col-4">
        <h3 className="text-primary text-center">Login to your account.</h3>
        <div className="form-group mt-3">
          <label id="email" className="primary" style={{ fontSize: "16px" }}>
            Email
          </label>
          <input
            className="w-100 primary w-75"
            type="text"
            placeholder="Enter Email"
            name="email"
            onKeyUp={(event: any) => {
              setEmail(event.target.value);
            }}
          ></input>
        </div>
        <div className="form-group mt-3">
          <label id="password" className="primary" style={{ fontSize: "16px" }}>
            Password
          </label>
          <input
            className="w-100 primary"
            type="password"
            placeholder="Enter Password"
            name="password"
            onKeyUp={(event: any) => {
              setPassword(event.target.value);
            }}
          ></input>
        </div>
        <p className="mt-3" style={{ textAlign: "end" }}>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("forgot-password");
            }}
          >
            Forgotten Your Password?
          </a>
        </p>
        <div className="d-flex mt-3">
          <div className="form-check align-self-start">
            <label className="form-check-label text-primary">
              <input
                type="checkbox"
                className="form-check-input"
                value=""
              ></input>
              Remember Me
            </label>
          </div>
          <button
            className="btn-primary"
            style={{ marginLeft: "auto", width: "15%" }}
            onClick={() => login()}
          >
            Login
          </button>
        </div>
        <span
          className="align-self-end text-primary mt-3"
          style={{ fontSize: "14px" }}
        >
          Don't have an account?{" "}
          <span style={{ cursor: "pointer" }}>
            <b>
              {" "}
              <Link className="text-danger text-decoration-none" to="register">
                Register Here
              </Link>
            </b>
          </span>
        </span>
      </div>
    </>
  );
}

export default Login;
